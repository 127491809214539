import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
} from "react-router-dom";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

import Home, { fetchCars } from "./pages/home/page";
import Range, { fetchModels } from "./pages/range/page";
import Model, { fetchCar } from "./pages/model/page";
import Dealerships, { fetchDealerships } from "./pages/dealership/page";
import Service, { fetchServiceData } from "./pages/service/page";
import Contact from "./pages/contact/page";
import Privacy from "./pages/privacy/page";
import Terms from "./pages/terms/page";
import Error from "./pages/error/page";

import company from "./data/company.json";

const RootLayout = () => {
  return (
    <div>
      <header className="bg-white sticky top-0 z-50">
        <NavBar {...company} />
      </header>

      <main className="flex flex-col flex-grow">
        <Outlet />
      </main>
      <footer>
        <Footer {...company} />
      </footer>
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<Error />}>
      <Route index element={<Home />} loader={fetchCars} />
      <Route
        path="/range/:rangeName"
        element={<Range />}
        loader={fetchModels}
      />
      <Route
        path="/range/model/:modelID"
        element={<Model />}
        loader={fetchCar}
      />
      <Route
        path="/service/:dealerID"
        element={<Service />}
        loader={fetchServiceData}
      />
      <Route
        path="/dealership"
        element={<Dealerships />}
        loader={fetchDealerships}
      />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
    </Route>
  )
);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
